<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Plants</h1>
        <p> Let's start with a little disappointment. We are not a vegetable farm or market garden.</p>
        <p>We are located in a landscape called Dehesa in Spanish. It is
          an open forest that has been used for centuries for extensive ranching. There are no fields that are
          cultivated with a tractor or what else one would associate with farming.</p>
        <p>However, we do focus a lot on plants.</p>
        <p>There are two categories of plants that are most important to us. The centuries old oak trees (mostly Quercus
          ilex sp. bellota) and a lot of different grasses. The oak trees produce a lot of acorns which are an important
          feed for the <router-link :to="{ name: 'animalsPigs' }">Iberian Pig</router-link>. The grasses feed our
          <router-link :to="{ name: 'animalsCattle' }">cattle</router-link> and other ruminants.
        </p>
        <p>Further, all that we do needs to be seen in the context of an <router-link :to="{ name: 'concept' }">ecosystem
            restoration project</router-link> and not only from
          the perspective of agricultural production. Step by step we are working towards managing the whole instead of
          a focus on a few parts that might only be of monetary interest. Over time more details about what we do will
          be published.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "PlantsEN",
  components: {
    Sidebar,
  },

};
</script>
